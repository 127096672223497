<template>
  <div class="home-container" v-if="showPage">
    <div class="header-container">
      <img class="house" :src="require('@/static/image/icons/house.png')"/>
      <div class="user-info-container">
        <div class="avatar">
          <img :src="dataInfo.user_info.real_avatar"/>
        </div>
        <div class="info">
          <div class="username">{{ dataInfo.site_name }}</div>
          <div class="mobile">{{ dataInfo.user_info.real_name }} / {{ dataInfo.user_info.mobile }}</div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="left" @click="goToMoneyCenter">
        <div class="title">佣金</div>
        <div class="value-text">{{ dataInfo.commission }}</div>
      </div>
      <div class="vertical-line"></div>
      <div class="right" @click="goFinshUrl">
        <div class="title">已完成订单</div>
        <div class="value-text">{{ dataInfo.total_count }}</div>
      </div>
    </div>

    <scrollview @onRefresh="onRefresh" :height="scrollHeight">
      <div class="card-header" style="margin-top: 20px;">
        <div class="vertical-line"></div>
        <div class="card-title">订单统计</div>
        <div class="card-extends">

        </div>
      </div>

      <div class="card-list">
        <div class="card" @click="goToList(1)">
          <div class="card-title">
            配送中订单
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            {{ dataInfo.on_the_way }}个
          </div>
        </div>

        <div class="card" @click="goToList(2)">
          <div class="card-title">
            待客户领取订单
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            {{ dataInfo.un_finish_count }}个
          </div>
        </div>

        <div class="card" @click="goFinshUrl">
          <div class="card-title">
            已完成订单
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            {{ dataInfo.total_count }}个
          </div>
        </div>


        <div class="card">
          <div class="card-title" @click="goToList(3)">
            已超时订单
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            {{ dataInfo.over_count }}个
          </div>
        </div>

        <div class="card">
          <div class="card-title" @click="goToList(4)">
            即将配送订单
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            {{ dataInfo.will_send_order }}个
          </div>
        </div>

        <div class="card">
          <div class="card-title" @click="viewSendingGoods">
            配送中商品
            <img :src="require('@/static/image/icons/arrow-right-v3.png')"/>
          </div>
          <div class="card-text">
            查看
          </div>
        </div>
      </div>

      <template v-if="dataInfo.isManager == 1">
        <div class="card-header">
          <div class="vertical-line"></div>
          <div class="card-title">员工管理</div>
          <div class="card-extends"></div>
        </div>

        <div class="card-list">
          <div class="card" @click="addWorker">
            <div class="card-button">
              <img :src="require('@/static/image/icons/add-user-v3.png')"/>
              添加自提点员工
            </div>
          </div>

          <div class="card" @click="workerList">
            <div class="card-button">
              <img :src="require('@/static/image/icons/remove-user-v3.png')"/>
              自提点员工列表
            </div>
          </div>
        </div>
      </template>


      <div class="page-buttons">
        <button class="mobile-buttons" v-debounce="['click', showMobileSearch, 500]">
          搜索手机号
        </button>
        <button class="scan-buttons" v-debounce="['click', scanQRCode, 500]">
          扫码领件
        </button>
      </div>




    </scrollview>
    <nut-popup
        v-model="show"
        round
        position="bottom"
        :style="{ height: '40%' }"
        :close-on-click-overlay="true"
    >
      <div class="confirm-window">
        <div class="title">客户手机号搜索</div>
        <div class="search-container">
          <nut-searchbar
              v-model="mobile"
              placeText="客户手机号码"
              @submit="searchMobile"
              :hasTextButton="true"
              textInfo="搜索订单"
              customClass="searchCustomClass"
          ></nut-searchbar>
        </div>
        <div class="warning-text">
          请尽量输入更多位数的手机号码进行精确检索
        </div>
      </div>
    </nut-popup>
  </div>
</template>

<script>
export default {
  name: 'home-new',
  data() {
    return {
      scrollHeight: 0,
      dataInfo: {},
      supper_admin: 0,
      showPage: false,
      show:false,
      mobile:''
    }
  },
  mounted() {
    this.myCenterInfo()
    setTimeout(() => {
      this.scrollHeight = window.innerHeight - document.querySelector('.header-container').clientHeight - 90
    }, 1000)

  },
  methods: {
    searchMobile(){
      if(this.mobile){
        let params = {
          mobileKeyword:this.mobile
        }
        this.goToList(2,params)
        this.show = false
      }
    },
    onRefresh(done) {
      this.myCenterInfo().finally(() => {
        done()
      })
    },
    goToList(status,params = {}) {
      let queryParams = {
        status:status,
        ...params
      }
      if (this.$platform.wxsdk.isWechat()) {
        let query = encodeURIComponent(JSON.stringify(queryParams))
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/selfExtract/order-list?query=' + query,
        })
      } else {
        this.$router.push({path: 'order-list', query: queryParams})
      }
    },
    goFinshUrl() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/selfExtract/done-order-list',
        })
      } else {
        this.$router.push({path: 'done-order-list'})
      }
    },
    goToSend(url_id, url_pick_up_code) {
      if (this.$platform.wxsdk.isWechat()) {
        let query = encodeURIComponent(
            JSON.stringify({id: url_id, pick_up_code: url_pick_up_code})
        )
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          url: '/web/selfExtract/scan-user-result?query=' + query,
        })
      } else {
        this.$router.push({
          path: 'scan-user-result',
          query: {id: url_id, pick_up_code: url_pick_up_code},
        })
      }
    },
    scanQRCode() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          // type: 'redirectTo',
          url: '/web/scancode/selfExtract',
        })
      }
    },
    showMobileSearch(){
      this.show = true
    },
    addWorker() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          // type: 'redirectTo',
          url: '/web/selfExtract/worker/add-worker',
        })
      }
    },
    viewSendingGoods() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          // type: 'redirectTo',
          url: '/web/selfExtract/sending-goods',
        })
      }
    },
    workerList() {
      if (this.$platform.wxsdk.isWechat()) {
        this.$platform.wxsdk.wxRoute({
          type: 'navigateTo',
          // type: 'redirectTo',
          url: '/web/selfExtract/worker/worker-list',
        })
      }
    },
    search() {
      searchOrder({
        show: true,
        success: (res) => {
          // console.log("搜索到了订单", res);
          this.goToSend(res.id, res.pick_up_code_url)
        },
      })
    },
    async myCenterInfo() {
      try {
        let query = await this.$api.community.points.myCenterInfo()
        this.dataInfo = query.data
        this.supper_admin = query.data.isManager
        this.showPage = true
        let that = this
        if (this.dataInfo.over_count > 0) {
          this.$dialog({
            title: "逾期未处理订单",
            content: "您的自提点有 " + this.dataInfo.over_count + " 条订单未处理，请尽快联系客户进行取货",
            closeOnClickModal: false,
            okBtnTxt:'点击查看',
            cancelBtnTxt:'稍后处理',
            closeBtn: false,
            onOkBtn:() => {
              that.$dialog.closed()
              that.goToList(3)
            }
          });
        }
      } catch (error) {
        console.log('dataInfo error', error)
      }
    },
    goToMoneyCenter() {
      if (this.supper_admin == 1) {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/selfExtract/with-drawal',
          })
        } else {
          this.$router.push({
            path: 'with-drawal',
          })
        }
      } else {
        this.$toast.text('非管理员不能提现')
      }
    }
  }
}
</script>

<style scoped lang="scss">

.confirm-window {
  display: flex;
  flex-direction: column;

  .title {
    flex: 1;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
    padding: 40px 0 15px 0;
  }

  .search-container{
    padding: 20px 30px;

    .searchCustomClass{
      ::v-deep .search-input input{
        height:30px !important;
        font-size:14px !important;
      }

      ::v-deep .btn-search{
        font-size:14px !important;
      }
    }
  }

  .warning-text{
    margin-top: 20px;
    font-size:14px;
    color: #666;
    display:flex;
    justify-content:center;
    color: #ff0000;
  }

}

.home-container {
  background: #f6f6f6;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .header-container {
    height: 130px;
    background: linear-gradient(135deg, #ffb56d 0%, #ff8116 100%);
    position: relative;
    width: 100%;
    z-index: 200;

    .house {
      position: absolute;
      width: 174px;
      height: 120px;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    .user-info-container {
      display: flex;
      padding: 20px;

      .avatar {
        width: 50px;
        height: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        overflow: hidden;
        padding: 1px;
        border: #fff solid 1px;
        margin-right: 10px;

        img {
          width: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        color: #fff;
        padding: 10px 0;

        .username {
          flex: 1;
          font-size: 20px;
        }

        .mobile {
          font-size: 14px;
        }
      }
    }
  }

  .header-bottom {
    margin: -35px 20px 0 20px;
    background: #fff;
    box-shadow: 0px 2px 12px 0px rgba(215, 215, 215, 0.5);
    border-radius: 8px;
    height: 70px;
    z-index: 999;
    position: relative;
    display: flex;
    align-items: center;

    .vertical-line {
      width: 1px;
      height: 70%;
      border-left: #EDEDED solid 1px;
    }

    .left, .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 10px 12px 10px 20px;
      align-items: flex-start;

      .title {
        font-size: 14px;
        color: #979797;
      }

      .value-text {
        color: #E40000;
        font-size: 16px;
        margin-top: 4px;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
      }
    }

  }

  .card-header {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .vertical-line {
      width: 4px;
      height: 16px;
      background: linear-gradient(180deg, #FBA332 0%, #F46A17 100%);
      border-radius: 2px;
    }

    .card-title {
      font-size: 16px;
      color: #303030;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-left: 6px;

    }

    .card-extends {
      flex: 1;
      justify-content: flex-end;
    }
  }

  .card-list {
    display: flex;
    padding: 20px;
    flex-column: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .card {
      width: 132px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      padding: 15px;

      .card-title {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        display: flex;
        align-items: center;

        img {
          width: 6px;
          height: 12px;
          margin-left: 5px;
        }
      }

      .card-text {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303030;
        line-height: 14px;
      }

      .card-button {
        flex: 1;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        align-items: center;

        img {
          width: 21px;
          height: 21px;
          margin-right: 10px;
        }
      }
    }
  }
}

.page-buttons{
  display: flex;
  padding: 0 20px;

  .mobile-buttons{
    margin: 0 auto;
    margin-top: 1px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 45px;
    background: linear-gradient(315deg,#ff4f18,#f20000);
    background-clip: padding-box;
    border-radius: 29px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin-right: 20px;
  }

  .scan-buttons {
    margin: 0 auto;
    margin-top: 1px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 45px;
    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
    background-clip: padding-box;
    border-radius: 29px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}


</style>